// const domain = "http://yp.cn/"
const domain = "https://admin.zangyaog.com"




import axios from 'axios'
function request(url, data, method) {
	var token = ''
	if (sessionStorage.getItem('token')) {
		token = sessionStorage.getItem('token')
	}
	return new Promise((resolve) => {
		axios({
			url: domain + url,
			headers: {
				'content-Type': 'application/json',
				"token": token
			},
			data,
			method: method || 'POST'
		}).then(res => {
			return resolve(res.data)
		})

	})
}
export {
	request,
	domain
};
